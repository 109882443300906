import { SectionHeading, SectionTitle } from '../styles'
import { Container, ItemContainer } from './styles'
import dynamic from 'next/dynamic'

const CardItem = dynamic(() => import('./CardItem'))

const ThemeContent = ({ sectionTitle, contents, viewType }) => {
    const slicedTitle = (title) =>
        title.length > 55 ? title.slice(0, 55) + '...' : title
    const filteredContents =
        contents.length &&
        contents.filter((content) => {
            return (
                content?.value !== 'trendingepisode' &&
                content?.value !== 'topranking'
            )
        })
    return (
        <Container>
            <SectionHeading>
                <SectionTitle>{slicedTitle(sectionTitle)}</SectionTitle>
            </SectionHeading>
            <ItemContainer>
                {filteredContents.length &&
                    filteredContents.map((item) => (
                        <CardItem
                            key={item.id + Math.random(5)}
                            id={item.id}
                            image={item.image}
                            imageMeta={item?.imageMeta}
                            value={item.value}
                            duration={item.duration}
                            entityType={item.entityType}
                            entitySubType={item.entitySubType}
                            viewType={viewType}
                            category={item.category}
                            type={item.type}
                        />
                    ))}
            </ItemContainer>
        </Container>
    )
}

export default ThemeContent
