import { ColumnFlex, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    ${ColumnFlex}
`
export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0 0 0 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
export const CardAnchor = styled.a`
    width: 285px;

    @media (max-width: 600px) {
        width: 50%;
    }
`
export const CardContainer = styled.div`
    cursor: pointer;
    width: 285px;
    height: 124.73px
    ${RowFlex}
    justify-content: flex-start;
    align-items: center;
    padding-right: 15px;
    padding-bottom: 15px;

    @media (max-width: 600px) {
        width: 100%;
        height: auto
    }
`
export const CardImage = styled.img`
    border-radius: 11px;
    width: 285px;
    height: 124px;
    object-fit: cover;

    @media (max-width: 600px) {
        border-radius: 8px;
        height: 100px;
    }
`
export const CardContent = styled.div`
    margin-left: 15px;
    margin-right: auto;
    padding-right: 10px;
    line-height: 19px;
`
export const CardTitle = styled.h2`
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 0 5px;
    letter-spacing: 0.03em;
    line-height: 20px;
    margin: 0;
`
export const CardAuthor = styled.span`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #707070;
`
